<template>
  <div class="wrapper">
    <div class="auth">
      <div class="auth__container">
        <div class="auth-bg">
          <div class="auth-bg__text">
            <span v-if="activeEvent">{{ activeEvent.title }}</span>
            <!-- <span>FORESIGHT FORUM</span> -->
          </div>
        </div>
        <v-card
          v-if="!hash"
          class="auth-form"
          rounded="xl"
        >
          <c-img
            class="circle-small auth-form__circle-2"
            src="assets/img/theme/modal-bottom-element.png"
            alt="circle1"
          />
          <c-img
            class="circle-big auth-form__circle-1"
            src="assets/img/theme/modal-top-element.png"
            alt="circle2"
          />
          <v-card-title class="auth-form__header">
            <div class="auth-form__header-inner">
              <div class="auth-form__header-top">
                <div v-if="activeEvent" class="auth-form__header-caption">
                  {{ $t('welcome to foresight winter') }}
                </div>
                <div class="auth-form__logo">
                  <c-img
                    src="assets/img/theme/event-logo-light.png"
                    alt="logo"
                  />
                </div>
              </div>
              <div
                v-if="currentEventStaticData"
                class="auth-form__header-description"
              >
                {{ $t('foresight winter will take') }}
              </div>
            </div>
          </v-card-title>
          <v-form
            ref="form"
            class="auth-form__form"
            @submit.prevent="onSubmit"
          >
            <div class="auth-form__body">
              <v-text-field
                v-model="username"
                class="auth-form__field"
                outlined
                name="login"
                :label="$t('login')"
                :rules="[requiredRule]"
              />
              <v-text-field
                v-model="password"
                class="auth-form__field"
                outlined
                name="password"
                :label="$t('password')"
                :rules="[requiredRule]"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
            </div>
            <v-card-actions class="auth-form__bottom">
              <v-btn
                class="auth-form__submit-btn"
                min-height="60"
                min-width="220"
                type="submit"
                :disabled="isLoading"
                :loading="isLoading"
              >
                {{ $t('submitButton') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
        <v-overlay :value="isLoading">
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import store from './store'
import { mapActions } from 'vuex'
import { notifyError } from '@/utils/notify'
import { requiredRule } from '@/utils/validate'
import businessEventService from '@/services/businessEvent'
import { getCurrentEventStaticData } from '@/utils/common'

export default {
  store,

  data: () => ({
    activeEvent: null,
    username: null,
    password: null,
    showPassword: false,
    isLoading: false,
    hash: null,
    id: null,
    currentEventStaticData: null
  }),

  async created () {
    this.activeEvent = await businessEventService.getActiveEvent()
    this.setDocumentTitle()
    this.currentEventStaticData = getCurrentEventStaticData(this.activeEvent.id)
    this.setActiveEventSelector()
    this.hash = this.$route.query.hash?.replace('some_hash', '')
    if (this.hash) {
      this.tryLogin({ hash: this.hash })
    }
  },

  methods: {
    ...mapActions(['login']),

    setDocumentTitle () {
      document.title = this.activeEvent.title
    },

    setActiveEventSelector () {
      document.documentElement.classList.add(`event-${this.activeEvent.id}`)
    },

    onSubmit () {
      if (!this.$refs.form.validate()) return
      this.tryLogin({
        credits: {
          username: this.username,
          password: this.password
        }
      })
    },

    async tryLogin ({ credits, hash }) {
      try {
        this.isLoading = true
        await this.login({ credits, hash })
        this.$router.push('/')
      } catch (err) {
        if (err.response?.status === 404) {
          notifyError(this.$t('errors.invalidLogin'))
        } else {
          notifyError(err.response?.data?.error || err.message)
          console.error(err)
        }
        this.isLoading = false
        this.hash = null
      }
    },

    requiredRule
  }
}
</script>

<style lang="scss">
.page-auth {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
}

.auth-card {
  max-width: 100%;
}
</style>
